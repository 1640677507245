import { useMutation, useQuery } from '@apollo/client';
import GoogleMapReact from 'google-map-react';
import { pick } from 'lodash';
import Image from 'next/image';
import React, { useState } from 'react';
import { Share, Trash2 } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import Heading from 'components/common/heading';
import InfoBox from 'components/common/infoBox';
import Link from 'components/common/link';
import Loader, { LoaderType } from 'components/common/loaders';
import useModal, { Modal } from 'components/common/modal/useModal';
import Container from 'components/container';
import IdealistActions from 'components/idealist/idealistActions';
import IdealistCompletionStats from 'components/idealist/idealistCompletionStats';
import IdealistDetails from 'components/idealist/idealistDetails';
import { IdealistStats } from 'components/idealist/types';
import { NEXT_PUBLIC_GOOGLE_MAPS_API_KEY } from 'constants/env';
import { IDEALIST_PLACEHOLDER_IMAGE } from 'constants/images';
import { EDIT_IDEALIST_URL, PROFILE_URL, PUBLIC_IDEALIST_URL } from 'constants/urls';
import { GET_IDEALISTS } from 'containers/idealists/idealists.query';
import { MIN_ITEMS_FOR_NON_DRAFT_IDEALIST } from 'graphql/constants/idealist';
import {
  DeleteIdealistMutation,
  DeleteIdealistMutationVariables,
  GetIdeaListQuery,
  GetIdeaListQueryVariables,
  GetIdealistsQuery,
  GetIdealistsQueryVariables,
  IdealistItem,
  Idealist as IdealistType,
  MakeIdealistPublicMutation,
  MakeIdealistPublicMutationVariables,
  User,
} from 'graphql/generated';
import { VerifyUser } from 'graphql/withAuthenticatedUser';
import { useAuthenticatedUser } from 'utils/auth';
import { mq } from 'utils/responsive';
import { theme } from 'utils/theme';
import { useStaticRouter } from 'utils/useStaticRouter';
import IdeaListItem from '../../components/idealist/ideaListItem';
import { DELETE_IDEALIST, GET_IDEALIST, MAKE_IDEALIST_PUBLIC } from './idealist.query';
import ImagesRow from './imagesRow';

const IdeaListDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
`;

const IdeaListMetadataBox = styled.div`
  padding: 15px 40px 15px 40px;

  ${mq.ltsm`
    padding: 15px 10px 15px 10px;
  `}
`;

const CenteredImageDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 340px; // for very tall images
  overflow: hidden;
`;

const IdealistOwnerActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`;

interface IdealistProps {
  idealist: Pick<
    IdealistType,
    'id' | 'title' | 'description' | 'image' | 'isDraft' | 'createdAt' | 'location' | 'images'
  > & {
    creator: Pick<User, 'id' | 'name' | 'image' | 'username'>;
    completedBy?: Pick<User, 'id' | 'name' | 'image'>[];
  };
  hasTitle: boolean;
  hasDescription: boolean;
  user: VerifyUser | null;
  idealistItems: IdealistItem[];
  idealistStats: IdealistStats;
  actionsDoNothing?: boolean;
}

const IdealistLocationDiv = styled.div`
  width: 100%;
  height: 250px;
  margin-top: 12px;
`;

export const Idealist = ({
  idealist,
  hasTitle,
  hasDescription,
  user,
  idealistItems,
  idealistStats,
  actionsDoNothing,
}: IdealistProps) => {
  const [mainImage, setMainImage] = useState(idealist.image);
  const [deleting, setDeleting] = useState(false);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const router = useStaticRouter();
  const fullScreenImageModal = useModal();

  const [makeIdealistPublic] = useMutation<
    MakeIdealistPublicMutation,
    MakeIdealistPublicMutationVariables
  >(MAKE_IDEALIST_PUBLIC);

  const [deleteIdealist] = useMutation<DeleteIdealistMutation, DeleteIdealistMutationVariables>(
    DELETE_IDEALIST,
    {
      optimisticResponse: {
        deleteIdealist: idealist.id,
      },
      update(cache, { data }) {
        if (!data) {
          return;
        }

        const deletedIdealistId = data.deleteIdealist;
        const variables: GetIdealistsQueryVariables = { userId: user?.id, includeDraft: true };
        const getIdealistsCache = cache.readQuery<GetIdealistsQuery, GetIdealistsQueryVariables>({
          query: GET_IDEALISTS,
          variables,
        });

        if (!getIdealistsCache) {
          return;
        }

        cache.writeQuery<GetIdealistsQuery, GetIdealistsQueryVariables>({
          query: GET_IDEALISTS,
          variables,
          data: {
            paginatedIdeaLists: {
              ...getIdealistsCache.paginatedIdeaLists,
              edges: getIdealistsCache.paginatedIdeaLists.edges.filter(
                ({ cursor }) => cursor !== deletedIdealistId,
              ),
            },
          },
        });
      },
    },
  );

  const loggedInUserIsCreator = user?.id && user.id === idealist.creator.id;

  return (
    <IdeaListDiv>
      <FullWidthDiv>
        <IdeaListMetadataBox>
          <Heading level={200} color={hasTitle ? undefined : theme.colors.gray500}>
            {hasTitle ? idealist.title : `No title`}
          </Heading>

          <IdealistDetails idealist={idealist} />

          {idealist.isDraft && (
            <InfoBox
              fullWidth
              info={`Idealists without title, location, description and at least ${MIN_ITEMS_FOR_NON_DRAFT_IDEALIST} steps are marked as draft, and won't be visible to others.`}
            />
          )}

          {loggedInUserIsCreator ? (
            <IdealistOwnerActions>
              <Link href={EDIT_IDEALIST_URL(idealist.id)}>
                <SimpleButton borderColor={theme.colors.gray300}>
                  <Body margin="0">Edit</Body>
                </SimpleButton>
              </Link>
              <SimpleButton
                margin="0 0 0 10px"
                borderColor={theme.colors.gray300}
                backgroundColor={deleting ? theme.customColors.error : undefined}
                onClick={async () => {
                  if (deleting) {
                    await deleteIdealist({ variables: { id: idealist.id } });
                    router.push(PROFILE_URL);
                  } else {
                    setDeleting(true);
                    setTimeout(() => {
                      setDeleting(false);
                    }, 4000);
                  }
                }}
              >
                {!deleting && <Trash2 />}
                {deleting && (
                  <Body color={theme.colors.white} margin="0">
                    Delete
                  </Body>
                )}
              </SimpleButton>
              <SimpleButton
                margin="0 0 0 10px"
                borderColor={theme.colors.gray300}
                onClick={async () => {
                  setShareLinkCopied(true);
                  await makeIdealistPublic({ variables: { id: idealist.id } });
                  navigator.clipboard.writeText(PUBLIC_IDEALIST_URL(idealist.id));
                }}
              >
                {shareLinkCopied && <Body margin="0">Link copied!</Body>}
                {!shareLinkCopied && <Share />}
              </SimpleButton>
            </IdealistOwnerActions>
          ) : null}

          <Body margin="30px 0 20px 0" color={hasDescription ? undefined : theme.colors.gray500}>
            {hasDescription ? idealist.description : `No description`}
          </Body>

          {idealist.completedBy && <IdealistCompletionStats completedBy={idealist.completedBy} />}

          <IdealistActions
            actionsDoNothing={actionsDoNothing}
            idealist={idealist}
            idealistStats={idealistStats}
            isCreatedByCurrentUser={user?.id === idealist.creator.id}
          />

          <Modal {...fullScreenImageModal} ref={fullScreenImageModal.modalRef}>
            <Image
              src={mainImage || IDEALIST_PLACEHOLDER_IMAGE}
              width="0"
              height="0"
              sizes="100vw"
              alt="Idealist Image"
              style={{
                height: 'auto',
                width: '100%',
                maxWidth: '100%',
                minWidth: '100%',
                objectFit: 'cover',
                zIndex: 400,
              }}
            />
          </Modal>
          <CenteredImageDiv>
            <Image
              onClick={() => {
                fullScreenImageModal.openModal();
              }}
              src={mainImage || IDEALIST_PLACEHOLDER_IMAGE}
              width="0"
              height="0"
              sizes="100vw"
              alt={idealist.title}
              style={{
                height: 'auto',
                width: '100%',
                overflow: 'scroll',
              }}
              priority
              loading="eager"
            />
          </CenteredImageDiv>

          {!!idealist.images?.length && (
            <ImagesRow
              showAddMoreImagesButton={user?.id === idealist.creator.id}
              idealistId={idealist.id}
              onImageClick={(src) => {
                const openModal = src === mainImage; // it means someone clicked on it once to make it main, and clicked again
                setMainImage(src);

                if (openModal) {
                  fullScreenImageModal.openModal();
                }
              }}
              images={idealist.images?.map(({ src }) => src) || []}
            />
          )}
          {idealist.location?.lat && idealist.location.long && (
            <IdealistLocationDiv>
              <GoogleMapReact
                options={{ fullscreenControl: false }}
                bootstrapURLKeys={{ key: NEXT_PUBLIC_GOOGLE_MAPS_API_KEY }}
                defaultCenter={{
                  lat: parseFloat(idealist.location.lat),
                  lng: parseFloat(idealist.location.long),
                }}
                defaultZoom={11}
              />
            </IdealistLocationDiv>
          )}
        </IdeaListMetadataBox>
      </FullWidthDiv>

      <FullWidthDiv>
        <IdeaListMetadataBox>
          <Heading level={400}>Here&apos;s what {idealist.creator.name} did: </Heading>
        </IdeaListMetadataBox>
      </FullWidthDiv>

      {idealistItems.map((item, idx) => (
        <FullWidthDiv key={item.id}>
          <IdeaListItem item={item} position={idx + 1} />
        </FullWidthDiv>
      ))}
    </IdeaListDiv>
  );
};

interface IdealistContainerProps {
  id: string;
}

const IdealistContainer = ({ id }: IdealistContainerProps) => {
  const { data, loading } = useQuery<GetIdeaListQuery, GetIdeaListQueryVariables>(GET_IDEALIST, {
    variables: { id },
  });
  const user = useAuthenticatedUser();

  // TODO: handle error
  if (loading) {
    return (
      <Container centerContent>
        <Loader type={LoaderType.PACMAN} width="120px" />
      </Container>
    );
  }

  if (!data?.getIdeaList) {
    return (
      <Container centerContent>
        <Body>Could not find the list you are looking for.</Body>
      </Container>
    );
  }

  const idealistWithStats = data.getIdeaList;
  const idealistItems = idealistWithStats.idealist.items;
  const hasTitle = idealistWithStats.idealist.title.length > 0;
  const hasDescription = idealistWithStats.idealist.description.length > 0;
  const idealistStats = pick(idealistWithStats, ['likeCount', 'likedByUser', 'completedByUser']);

  return (
    <Container centerContent>
      <Idealist
        idealist={idealistWithStats.idealist}
        hasTitle={hasTitle}
        hasDescription={hasDescription}
        user={user}
        idealistItems={idealistItems}
        idealistStats={idealistStats}
      />
    </Container>
  );
};

export default IdealistContainer;
