import gql from 'graphql-tag';
import { FEED_IDEALIST_WITH_USER_STATS_FRAGMENT } from 'utils/fragments.query';

export const GET_IDEALISTS = gql`
  query GetIdealists(
    $after: ID
    $take: Int
    $userId: ID
    $includeDraft: Boolean
    $searchInput: String
  ) {
    paginatedIdeaLists(
      after: $after
      take: $take
      userId: $userId
      includeDraft: $includeDraft
      searchInput: $searchInput
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          ...FeedIdealistWithUserStats
        }
      }
    }
  }

  ${FEED_IDEALIST_WITH_USER_STATS_FRAGMENT}
`;
