import Image from 'next/image';
import { ArrowLeft, ArrowRight, X } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import EmptyButton from 'components/common/button/empty';
import SimpleButton from 'components/common/button/simple';
import Link from 'components/common/link';
import { EDIT_IDEALIST_URL } from 'constants/urls';
import { theme } from 'utils/theme';

const ImagesRowDiv = styled.div`
  width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-top: 10px;
  overflow: scroll;
  overflow-y: hidden;
`;

const ImageWithActionsDiv = styled.div`
  width: auto;
  height: 120px;
  max-height: 120px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
  cursor: pointer;
`;

const ActionsDiv = styled.div`
  display: flex;
  height: 20px;
`;

interface ImagesRowProps {
  idealistId: string;
  images: string[];
  imagesAlt?: string;
  onImageClick?: (src: string) => void;
  onDelete?: (idx: number) => void;
  onLeft?: (idx: number) => void;
  onRight?: (idx: number) => void;
  showActions?: boolean;
  showAddMoreImagesButton?: boolean;
}

const ImagesRow = ({
  idealistId,
  images,
  imagesAlt,
  onImageClick,
  onLeft,
  onDelete,
  onRight,
  showActions,
  showAddMoreImagesButton,
}: ImagesRowProps) => {
  return (
    <ImagesRowDiv>
      {images.map((image, idx) => (
        <ImageWithActionsDiv
          key={idx}
          onClick={() => {
            onImageClick?.(image);
          }}
        >
          {showActions && (
            <ActionsDiv>
              <EmptyButton
                type="button"
                disabled={idx === 0}
                margin="5px"
                onClick={() => {
                  onLeft?.(idx);
                }}
              >
                <ArrowLeft color={theme.colors.black} />
              </EmptyButton>
              <EmptyButton
                type="button"
                disabled={idx === images.length - 1}
                margin="5px"
                onClick={() => {
                  onRight?.(idx);
                }}
              >
                <ArrowRight color={theme.colors.black} />
              </EmptyButton>
              <EmptyButton
                type="button"
                margin="5px"
                onClick={() => {
                  onDelete?.(idx);
                }}
              >
                <X color={theme.colors.black} />
              </EmptyButton>
            </ActionsDiv>
          )}
          <Image
            src={image}
            width="0"
            height="0"
            sizes="100vw"
            alt={imagesAlt || 'Idealist Image'}
            style={{ height: '100%', width: 'auto', minWidth: '150px', objectFit: 'cover' }}
          />
        </ImageWithActionsDiv>
      ))}
      {showAddMoreImagesButton && images.length === 1 && (
        <Link href={EDIT_IDEALIST_URL(idealistId)}>
          <SimpleButton borderColor={theme.colors.gray300} wrapWhiteSpace width="120px">
            <Body leftAlign margin="0 0 0 10px">
              Add more images!
            </Body>
          </SimpleButton>
        </Link>
      )}
    </ImagesRowDiv>
  );
};

export default ImagesRow;
