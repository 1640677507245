import GoogleMapReact from 'google-map-react';
import React from 'react';
import { Link as LinkIcon } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import Link from 'components/common/link';
import { NEXT_PUBLIC_GOOGLE_MAPS_API_KEY } from 'constants/env';
import { IdealistItem, IdealistLocation } from 'graphql/generated';
import { mq } from 'utils/responsive';
import { theme } from 'utils/theme';

const IdeaListItemOuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

const IdeaListItemInnerDiv = styled.div`
  width: 90%;
  border: 1px solid ${theme.colors.gray300};
  border-radius: 10px;

  display: flex;
  flex-direction: row;

  ${mq.ltsm`
    width: 100%;
    flex-direction: column;
  `}
`;

const ItemMetadataDiv = styled.div`
  width: 60%;
  padding: 10px 20px;
  width: 100%;

  display: flex;
  flex-direction: column;

  ${mq.ltsm`
    width: 100%;
  `}
`;

const ItemLocationDiv = styled.div`
  width: 40%;
  min-height: 100%;
  min-height: 100px;

  & div {
    border-radius: 0 9px 9px 0; // to round the corners of the map
  }

  ${mq.ltsm`
    width: 100%;
    height: 100px;

    & div {
      border-radius: 0 0 9px 9px; // to round the corners of the map
    }
  `}
`;

const PositionActionsDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

interface IdeaListItemProps {
  item: IdealistItem;
  position: number;
  location?: Pick<IdealistLocation, 'lat' | 'long'>;
}

const IdeaListItem = ({ item, position, location }: IdeaListItemProps) => {
  const content = (
    <IdeaListItemOuterDiv>
      <IdeaListItemInnerDiv>
        <ItemMetadataDiv>
          <PositionActionsDiv>
            <Body color={theme.colors.gray600}>#{position}</Body>
            {item.link && <LinkIcon color={theme.customColors.linkBlue} />}
          </PositionActionsDiv>
          <Body color={theme.colors.black}>{item.description}</Body>
        </ItemMetadataDiv>

        {location?.lat && location?.lat !== '0' && location?.long && location?.long !== '0' && (
          <ItemLocationDiv>
            <GoogleMapReact
              options={{ fullscreenControl: false }}
              bootstrapURLKeys={{ key: NEXT_PUBLIC_GOOGLE_MAPS_API_KEY }}
              defaultCenter={{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }}
              defaultZoom={11}
            />
          </ItemLocationDiv>
        )}
      </IdeaListItemInnerDiv>
    </IdeaListItemOuterDiv>
  );
  return item.link ? (
    <Link stretchParent href={item.link} newTab>
      {content}
    </Link>
  ) : (
    content
  );
};

export default IdeaListItem;
